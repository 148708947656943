import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { useMediaQuery } from 'react-responsive';

const App = ({ columnnn, dataaa, isLoadinggg }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 300);

  // Update the height dynamically when the window is resized
  useEffect(() => {
    const handleResize = () => setTableHeight(window.innerHeight - 200);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Table
      size="small"
      columns={columnnn}
      dataSource={dataaa}
      scroll={{ y: tableHeight }}
      loading={isLoadinggg}
      pagination={{
        pageSize: 10,
        pageSizeOptions: isMobile ? ["5", "10"] : ["10", "20", "30", "40"],
        showSizeChanger: !isMobile,
      }}
    />
  );
};

export default App;