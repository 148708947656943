import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Upload,
  Col,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  createInventoryreducer,
  getProductsSearchReducer,
} from "../../store/inventoryReducer";
import {
  UploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  getCategoryReducerForActive,
} from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  getProductReducer,
} from "../../store/productReducer";
import TableA from "../../components/tables/table";
import { toast } from "react-toastify";
const { Option } = Select;


const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };


  const [ setFields] = useState([]);
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [category, setCategory] = useState("");
  const [productQuery] = useState("");
  const [warehouseName, setWarhousename] = useState("");
  const { inventories } = useSelector((state) => state.inventory);
  const [inventorystatus, setInventoryStatus] = useState(""); // to know wheather it is incoming or outgoing
   

  const [handleModal, setHandleModal] = useState("");
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductReducer());
    dispatch(getCategoryReducerForActive());
    dispatch(getActiveWarehouseReducer());
    setQuantityData([]);
    setWarhousename(crdentialDataForOne?.warehouse);
  }, [dispatch,crdentialDataForOne]);

  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };

  const addIncomingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createInventoryreducer({
        productCategory: category,
        warehouse: crdentialDataForOne?.warehouse,
        product: productId,
        quantity: arr,
      })
    );
    setOpenModal(false);
  };
  const showHelp = () => {
    //
  };

  const handleFileChange = (event) => {
    setSelectedFile(event?.fileList[0]?.originFileObj);
    // console.log(selectedFile);
  };

  const handleUpload = () => {
    const token = localStorage.getItem("token");
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("category", category);
    formData.append("warehouse", warehouseName);
    axios
      .post(
        "http://192.168.100.54:5000/api/inventory/incoming/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Excel imported Successfully.");
      })
      .catch((error) => {
        console.error("EORRRR", error);
        // Handle any errors
      });
  };

  const {  products } = useSelector(
    (state) => state.product
  );

  const displayModal = (record, handleModal) => {
    setHandleModal(handleModal);
    if (handleModal === "OutgoingInventory") {
      if (warehouseName === "") {
        toast("Please select warehouse");
      } else {
        setProductQuantity(record?.quantity);
        setProductId(record?._id);
        setOpenModal(true);
        setInventoryStatus("incoming");
      }
    } else {
      setOpenModal(true);
    }
  };

  const onCategoryChange = (event) => {
    // dispatch(getAllproductByCategory(event));
    setCategory(event);
    const filteredData = products?.data?.filter(
      (item) => item.category === event
    );
    setFields(filteredData);
  };



  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const onWarehouseChange = (evt) => {
    setWarhousename(evt);
  };

  const onReset = () => {
    form.resetFields();
  };

  const columnsA = [
    {
      title: "Product Name",
      width: "10%",
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: "Product Description",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.description}
          <br />
        </span>
      ),
      ellipsis: true,
    },
    
    {
      title: "Quantity",
      width: "35%",
      render: (text, record) => (
        <span>
          {record.quantity}
          <br />
        </span>
      ),
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "OutgoingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-4">
      <Modal
        centered
        onOk={inventorystatus === "incoming" ? addIncomingInventory : showHelp}
        onCancel={() => setOpenModal(false)}
        title={
          inventorystatus === "incoming"
            ? "Incomming Inventory"
            : "How to import Excel"
        }
        visible={openModal}
      >
        {handleModal === "OutgoingInventory" ? (
          <Row gutter={5}>
            {productQuantity?.map((item) => (
              <Col xs={24} lg={24}>
                <Form
                  layout="vertical"
                  form={form}
                  name="control-hooks"
                  onFinish={onFinish}
                  style={{
                    width: 300,
                  }}
                >
                  <Form.Item className="capitalize" name={item} label={item}>
                    <Input
                      name={item}
                      value={item}
                      onChange={handleQuantityData}
                    />
                  </Form.Item>
                </Form>
              </Col>
            ))}
          </Row>
        ) : (
          <div>
            <p>
              1. first you must check the column names in the product
              specification and the excel column names you want to import
            </p>
            <p>2. you must import only one excel sheet at a time</p>
          </div>
        )}
      </Modal>
      <div>
        <p className="text-xl mb-2">Incoming Inventory</p>
        <Row gutter={20}>
          <Col xs={24} sm={24} lg={8}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                label="Warehouse"
                rules={[
                  {
                    required: true,
                    message: "Warehouse is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Warehouse"
                  allowClear
                  disabled
                  name="warehouseName"
                  value={warehouseName}
                  onChange={onWarehouseChange}
                >
                  {warehouses?.map((item, key) => (
                    <Option key={key} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item name="productname" label="Product Name">
                <Input
                  value={productQuery}
                  name="productQuery"
                  onChange={handleProductQuery}
                  placeholder="Search by Product"
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item>
              <Button onClick={() => displayModal("", "")}>Help</Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} sm={24} lg={8}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                name="productCategory"
                label="Product Category"
                rules={[
                  {
                    required: true,
                    message: "Product Category is Required",
                  },
                ]}
              >
                <Select
                  name="category"
                  value={category}
                  onChange={onCategoryChange} //
                  placeholder="Select product category"
                  allowClear
                >
                  {categories?.map((item, key) => (
                    <Option key={key} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item>
              <Upload onChange={handleFileChange}>
                <Button
                  icon={<UploadOutlined />}
                  className="custom-upload-button w-full"
                >
                  Upload Excel
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Button onClick={handleUpload} className="" htmlType="submit">
              Add Inventory
            </Button>
          </Col>
        </Row>
        <div>
          <TableA columnnn={columnsA} dataaa={inventories} />
        </div>
      </div>
    </div>
  );
};
export default Incominginventory;
