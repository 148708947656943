import React, { useEffect, useState } from "react";
import { Row, Col, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryInAllStockReducer,
  inventorySearchQuery,
} from "../../store/inventoryReducer";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import TableA from "../../components/tables/table";

const { Option } = Select;

const InventoryInAllStock = () => {
  const dispatch = useDispatch();
  
  const { allInventries, isLoading } = useSelector((state) => state.inventory);
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getActiveWarehouseReducer());
    dispatch(getCategoryReducerForActive());
    dispatch(getInventoryInAllStockReducer());
  }, [dispatch]);

  useEffect(() => {
    const dataSource = Object.keys(allInventries).map((key) => ({
      key,
      ...allInventries[key],
    }));
  
    setFilteredData(
      dataSource
    );
  }, [allInventries]);

  const handleSearchQuery = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    dispatch(
      inventorySearchQuery({
        warehouseId: selectedWarehouse,
        categoryId: selectedCategory,
        productQuery: value,
      }))
     setTimeout(() => {
        const dataSource = Object.keys(allInventries).map((key) => ({
            key,
            ...allInventries[key],
          }));
          setFilteredData(
            dataSource
          );
     }, 300);
    
      
  };

  const columnsA = [
    {
      title: "Product Name",
      width: "15%",
      render: (text, record) => <span>{record?.product?.name}</span>,
    },
    {
      title: "Product Description",
      width: "15%",
      render: (text, record) => <span>{record?.product?.description}</span>,
    },
    {
      title: "Quantity",
      width: "20%",
      render: (text, record) => (
        <span>
          {record.quantity?.map((item, index) => (
            <li key={index}>
              {item.name}: <b>{item.value}</b>
            </li>
          ))}
        </span>
      ),
    },
  ];

  return (
    <div className="ml-10">
      <div>
        <p className="text-xl mb-4">Inventory in All Warehouses</p>
        <Row gutter={16}>
          <Col span={8}>
            <Select
              placeholder="Select Warehouse"
              onChange={setSelectedWarehouse}
              style={{ marginBottom: "20px", width: "100%" }}
            >
              {warehouses?.map((item) => (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              ))}
            </Select>
          </Col>

          <Col span={8}>
            <Select
              placeholder="Select Category"
              onChange={setSelectedCategory}
              style={{ marginBottom: "20px", width: "100%" }}
            >
              {categories?.map((item) => (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              ))}
            </Select>
          </Col>

          <Col span={8}>
            <Input
              placeholder="Search by Product Name or Description"
              value={searchQuery}
              onChange={handleSearchQuery}
              style={{ marginBottom: "20px" }}
            />
          </Col>

          <TableA
            columnnn={columnsA}
            isLoadinggg={isLoading}
            dataaa={filteredData}
          />
        </Row>
      </div>
    </div>
  );
};

export default InventoryInAllStock;